exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-audit-tsx": () => import("./../../../src/pages/audit.tsx" /* webpackChunkName: "component---src-pages-audit-tsx" */),
  "component---src-pages-documentation-template-tsx": () => import("./../../../src/pages/DocumentationTemplate.tsx" /* webpackChunkName: "component---src-pages-documentation-template-tsx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-01-task-app-intro-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/01-getting-started/01-task-app-Intro.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-01-task-app-intro-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-02-task-app-add-task-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/01-getting-started/02-task-app-add-task.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-02-task-app-add-task-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-03-task-app-complete-task-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/01-getting-started/03-task-app-complete-task.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-03-task-app-complete-task-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-04-task-app-task-overview-projection-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/01-getting-started/04-task-app-task-overview-projection.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-04-task-app-task-overview-projection-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-05-task-app-query-task-overview-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/01-getting-started/05-task-app-query-task-overview.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-05-task-app-query-task-overview-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-06-task-app-deploy-to-aws-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/01-getting-started/06-task-app-deploy-to-aws.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-01-getting-started-06-task-app-deploy-to-aws-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-01-command-api-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/03-the-4-use-cases/01-command-api.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-01-command-api-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-02-projection-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/03-the-4-use-cases/02-projection.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-02-projection-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-03-query-api-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/03-the-4-use-cases/03-query-api.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-03-query-api-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-04-command-policy-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/03-the-4-use-cases/04-command-policy.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-03-the-4-use-cases-04-command-policy-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-01-api-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/01-api.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-01-api-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-02-command-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/02-command.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-02-command-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-03-aggregate-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/03-aggregate.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-03-aggregate-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-04-event-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/04-event.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-04-event-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-05-readmodel-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/05-readmodel.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-05-readmodel-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-06-query-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/06-query.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-06-query-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-07-external-system-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/07-external-system.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-07-external-system-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-08-policy-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/04-use-case-elements/08-policy.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-04-use-case-elements-08-policy-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-01-use-case-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/05-use-case-configurations/01-use-case.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-01-use-case-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-02-schema-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/05-use-case-configurations/02-schema.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-02-schema-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-03-data-flow-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/05-use-case-configurations/03-data-flow.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-03-data-flow-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-04-value-object-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/05-use-case-configurations/04-value-object.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-04-value-object-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-06-naming-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/05-use-case-configurations/06-naming.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-05-use-case-configurations-06-naming-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-01-code-generation-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/06-code/01-code-generation.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-01-code-generation-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-02-code-protection-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/06-code/02-code-protection.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-02-code-protection-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-03-testing-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/06-code/03-testing.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-03-testing-mdx" */),
  "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-04-deploy-to-aws-mdx": () => import("./../../../src/pages/DocumentationTemplate.tsx?__contentFilePath=/builds/getcodebricks/website/content/src/documentation/06-code/04-deploy-to-aws.mdx" /* webpackChunkName: "component---src-pages-documentation-template-tsx-content-file-path-src-documentation-06-code-04-deploy-to-aws-mdx" */),
  "component---src-pages-documentation-tsx": () => import("./../../../src/pages/documentation.tsx" /* webpackChunkName: "component---src-pages-documentation-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */)
}

