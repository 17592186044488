import PiwikPro from '@piwikpro/react-piwik-pro';
require("prismjs/themes/prism-solarizedlight.css")

export const onClientEntry = () => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
        PiwikPro.initialize(
            process.env.GATSBY_PIWIK_CONTAINER_ID as string,
            process.env.GATSBY_PIWIK_CONTAINER_URL as string
        );
    }
}